<template lang="pug">
div
  page-header(:title='title', :titleNew='titleNew')
  form(@submit.prevent='gravar')
    .modal-body
      .form-horizontal
        vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
        input-form-text(textInput='Nome', :required='true', v-model='form.titulo', :value='form.titulo')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='perfil')  Permissão
          .col-sm-9
            ul(v-for='(m, i) in form.menu', :Key='i', v-if='form.menu.length')
              li
                label
                  input(type='checkbox', v-model='m.visualizar')
                  | {{ m.titulo }}
                ul(v-for='(sub, ks) in m.submenu', :key='ks')
                  li
                    label
                      input(type='checkbox', v-model='sub.visualizar')
                      | {{ sub.titulo }}
                      ul(v-for='(act, ia) in sub.actions', :key='ia')
                        li
                          label
                            input(type='checkbox', v-model='act.value')
                            | {{ act.name }}
    footer-button(:disabled='isActive')
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import MenuDefault from '@/modules/menu'
import Permission from '@/mixins/Permission'
import req from '@/components/request'
import {sucesso, erro} from '@/components/Message'

export default {
  components: {
    VueElementLoading,
    PageHeader,
    InputFormText,
    FooterButton
  },
  mixins: [Permission],
  data () {
    return {
      title: 'Grupo de Acesso',
      titleNew: '',
      form: {
        titulo: '',
        menu: []
      },
      isActive: false

    }
  },
  methods: {
    gravar () {
      const body = {
        title: this.form.title,
        menu: JSON.stringify(this.form.menu)
      }
      if (this.$route.params.id) {
        req(`/perfil/${this.$route.params.id}`, 'put', body).then(() => {
          sucesso('Atualizado...')
          this.$router.push('/admin/grupo-de-acesso')
        }).catch(response => {
          console.log('error: ' + response)
          erro()
        })
      } else {
        req('/perfil', 'post', body).then(() => {
          sucesso('Atualizado...')
          this.$router.push('/admin/grupo-de-acesso')
        }).catch(response => {
          console.log('error: ' + response)
          erro()
        })
      }
    },
    getDados () {
      req(`/perfil/${this.$route.params.id}`).then(resp => {
        this.form.titulo = resp.titulo
        this.form.menu = Object.assign(resp.menu ? JSON.parse(resp.menu) : null, MenuDefault)
        this.isActive = false
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    verifPermissao () {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted () {
    this.form.menu = JSON.parse(JSON.stringify(MenuDefault))
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
