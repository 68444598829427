const MenuDefault = [{
  titulo: 'Início',
  icone: 'menu-icon fa fa-tachometer',
  action: false,
  visualizar: false,
  submenu: [{
    titulo: 'Atualizacões',
    icone: 'menu-icon fa fa-caret-right',
    url: '/inicio/atualizacoes',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Visualizar'
    }]
  },
  {
    titulo: 'Chamados',
    icone: 'menu-icon fa fa-caret-right',
    url: '/inicio/chamados',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Visualizar'
    }]
  },
  {
    titulo: 'Minha Conta',
    icone: 'menu-icon fa fa-caret-right',
    url: '/inicio/minha-conta',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Editar'
    }]
  }]
},
{
  titulo: 'Sistema',
  icone: 'menu-icon fa fa-desktop',
  action: false,
  visualizar: false,
  submenu: [{
    titulo: 'Clientes',
    icone: 'menu-icon fa fa-caret-right',
    url: '/sistema/clientes',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar Cliente'
    }, {
      value: false,
      name: 'Editar Cliente'
    }, {
      value: false,
      name: 'Cadastrar Agenda'
    }, {
      value: false,
      name: 'Agenda Historico'
    }, {
      value: false,
      name: 'Cadastrar Vendas'
    }]
  },
  {
    titulo: 'Agenda',
    icone: 'menu-icon fa fa-caret-right',
    url: '/sistema/agenda',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar Cliente'
    }, {
      value: false,
      name: 'Editar Cliente'
    }, {
      value: false,
      name: 'Cadastrar Agenda'
    }, {
      value: false,
      name: 'Agenda Historico'
    }, {
      value: false,
      name: 'Cadastrar Vendas'
    }]
  },
  {
    titulo: 'Vendas',
    icone: 'menu-icon fa fa-caret-right',
    url: '/sistema/vendas',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar Cliente'
    }, {
      value: false,
      name: 'Editar Cliente'
    }, {
      value: false,
      name: 'Cadastrar Agenda'
    }, {
      value: false,
      name: 'Agenda Historico'
    }, {
      value: false,
      name: 'Cadastrar Vendas'
    }, {
      value: false,
      name: 'Editar Vendas'
    }, {
      value: false,
      name: 'Excluir Vendas'
    }, {
      value: false,
      name: 'Contrato Vendas'
    }, {
      value: false,
      name: 'Recibo Vendas'
    }]
  }
  ]
},
{
  titulo: 'Admin',
  icone: 'menu-icon fa fa-cog',
  action: false,
  visualizar: false,
  submenu: [
  {
    titulo: 'Mailling',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/mailling',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Especie',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/especie',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Tipo de Emprestimo',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/tipo-de-emprestimo',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Grupo de Acesso',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/grupo-de-acesso',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Orgão',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/orgao',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Usuários',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/usuarios',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  },
  {
    titulo: 'Status',
    icone: 'menu-icon fa fa-caret-right',
    url: '/admin/status',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Cadastrar'
    }, {
      value: false,
      name: 'Editar'
    }]
  }]
},
{
  titulo: 'Relatório',
  action: false,
  icone: 'menu-icon fa fa-signal',
  visualizar: false,
  submenu: [{
    titulo: 'Data',
    icone: 'menu-icon fa fa-caret-right',
    url: '/relatorio/data',
    visualizar: false,
    actions: [{
      value: false,
      name: 'Visualizar'
    }]
  }]
}]

export default MenuDefault
